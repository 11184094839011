
.eCommerce-container {
    height: 100%;
    ::v-deep > .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.main-box {
    padding: 20px;
}
.tab-title {
    padding-left: 15px;
    position: relative;
    &:before {
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        width: 5px;
        height: 20px;
        background: #2DC079;
        border-radius: 3px;
    }
    .popover-title {
        font-size: 18px;
        line-height: 1;
        display: inline-block;
    }
}
.sort-num {
    display: inline-block;
    background: #E7F6EF;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    line-height: 24px;
    text-align: center;
}
.sort-img {
    display: inline-block;
    img {
        display: block;
    }
}
.data-content {
    height: 350px;
    width: 100%;
    margin-top: 30px;
}
